import React, { useState } from 'react';
import axios from 'axios';
import { Navbar } from 'react-bootstrap';
import authenticationService from '../services/authenticationService';
import { useQuery } from 'react-query';

function HeaderStatus(props) {
  const [dcsText, setDcsText] = useState('');
  const [dcsColor, setDcsColor] = useState('');
  const [srsText, setSrsText] = useState('');
  const [srsColor, setSrsColor] = useState('');

  const { isLoading, error, isFetching } = useQuery("status",
    async () => {
      const { data } = await axios
        .get("https://api.markkoudstaal.nl/services", {
          headers: {
            "x-access-token": authenticationService.getToken(),
          }
        });
      const dcsInstances = data.dcs.instances;
      const srsInstances = data.srs.instances;

      if (dcsInstances === 0) {
        setDcsText('Stopped');
        setDcsColor('red');
      } else if (dcsInstances === 1) {
        setDcsText('Running');
        setDcsColor('green');
      } else if (dcsInstances > 0) {
        setDcsText(`${dcsInstances} instances`);
        setDcsColor('green');
      }

      if (srsInstances === 0) {
        setSrsText('Stopped')
        setSrsColor('red');
      } else if (srsInstances === 1) {
        setSrsText('Running');
        setSrsColor('green');
      } else if (srsInstances > 0) {
        setSrsText(`${srsInstances} instances`);
        setSrsColor('green');
      }
      return data;
    },
    {
      refetchInterval: 60000,
    });

  if (isLoading || isFetching) return null;
  if (error) return null;

  return (
    <Navbar.Collapse className="justify-content-center">
      <Navbar.Text className='headerStatusText' style={{ color: dcsColor }}>
        DCS: {dcsText}
      </Navbar.Text>
      <Navbar.Text className='headerStatusText' style={{ color: srsColor }}>
        SRS: {srsText}
      </Navbar.Text>
    </Navbar.Collapse>
  )
}

export default HeaderStatus;

import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import MissionComponent from './MissionComponent';
import { Form, Col, Container, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import authenticationService from '../services/authenticationService'
import { ReactQueryDevtools } from 'react-query/devtools'

function MissionList(props) {
  const queryClient = useQueryClient();
  const [filteredList, setFilteredList] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [filter, setFilter] = useState("");
  const { isLoading, error, data, isFetching } = useQuery("missions", async () => {
    const { data } = await axios
      .get("https://api.markkoudstaal.nl/missions", {
        headers: {
          "x-access-token": authenticationService.getToken(),
        }
      })
    return JSON.parse(data['missions']);
  });

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  };

  const addMutation = useMutation(() => {
    const formData = new FormData()
    formData.append("file", selectedFile);
    return axios.post("https://api.markkoudstaal.nl/missions", formData, {
      headers: {
        "x-access-token": authenticationService.getToken(),
        'Content-Type': 'multipart/form-data'
      }
    })
  }, {
    onSuccess: () => {
      setTimeout(() => { queryClient.invalidateQueries('missions') }, 500);
    },
    onError: (err, variables, onMutateValue) => {
      console.log(err);
    }
  })

  const handleChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  }

  useEffect(() => {
    if (filter !== "") {
      setFilteredList(data.filter(item => {
        return item.toLowerCase().includes(filter);
      }));
    } else {
      setFilteredList(data);
    }
  }, [filter, data])

  if (error) return <div className={'alert alert-danger'}>Error: {error.message}</div>
  if (isLoading || isFetching) return <div id="loading" className="loading">Loading..</div>
  return (
    <Container className="missionList">
      <Form className="uploadForm">
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.File onChange={onFileChange} />
            </Form.Group>
          </Col>
          <Col>
            <Button variant="primary" type="button" onClick={addMutation.mutate}>Upload </Button>
          </Col>
          <Col>
            {
              addMutation.error && (<div className={'alert alert-danger'} onClick={addMutation.reset}>Error: {addMutation.error.response.data.error}</div>)
            }
          </Col>
        </Form.Row>
      </Form>
      <Form>
        <Form.Control onChange={handleChange} className="searchBar" id="searchBar" type="text" placeholder="Search here" />
      </Form>
      <ListGroup as="ul" className="missionList">
        {
          filteredList && filteredList.map(mission => (
            <ListGroup.Item as="li" key={mission} >
              <MissionComponent missionName={mission} />
            </ListGroup.Item>
          ))
        }
      </ListGroup>
      <ReactQueryDevtools />
    </Container >
  )
}

export default MissionList;

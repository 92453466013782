import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Header from './Header.jsx';
import MissionList from './MissionList.jsx';
import { Container } from 'react-bootstrap';
import LoginForm from './LoginForm.jsx';
import authenticationService from '../services/authenticationService';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  }
});

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("userInfo") !== null) {
      authenticationService.verifyToken().then(
        response => {
          if (response.status === 200) {
            setLoggedIn(true);
            setLoading(false);
          } else {
            setLoggedIn(false);
            setLoading(false);
          }
        }
      ).catch(
        error => {
          if (error) {
            setLoggedIn(false);
            setLoading(false);
          }
        }
      )
    } else {
      setLoggedIn(false);
      setLoading(false);
    }
  }, [loggedIn, loading])

  if (loading) return <div id="loading" className="loading">Loading...</div>
  if (!loggedIn) return <LoginForm setLoggedIn={setLoggedIn} />
  return (
    <Container className="mainBody">
      <QueryClientProvider client={queryClient}>
        <Header setLoggedIn={setLoggedIn} />
        <MissionList />
      </QueryClientProvider>
    </Container>
  )
}

export default App;

import axios from 'axios';

const USER_API_BASE_URL = 'https://api.markkoudstaal.nl/user/';

class authenticationService {
  async login(credentials) {
    return axios.post(USER_API_BASE_URL + "authenticate", credentials);
  }

  getUserInfo() {
    if (localStorage.getItem("userInfo") !== null) {
      return JSON.parse(localStorage.getItem("userInfo"));
    } else {
      return "";
    }
  }

  getToken() {
    if (localStorage.getItem("userInfo") !== null) {
      return this.getUserInfo().token;
    } else {
      return "";
    }
  }

  verifyToken() {
    return axios.post(USER_API_BASE_URL + "verify", {}, {
      headers: {
        "x-access-token": this.getToken()
      }
    }).then(
      response => {
        return response;
      }
    ).catch(
      err => {
        return err;
      }
    )
  }

  logOut() {
    localStorage.removeItem("userInfo");
  }
}

export default new authenticationService();

import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import authenticationService from '../services/authenticationService';
import HeaderStatus from './HeaderStatus';

function Header(props) {
  const onClickHandler = (event) => {
    authenticationService.logOut();
    props.setLoggedIn(false);
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/">
        Bandbox Mission Editor
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <HeaderStatus />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <Button onClick={onClickHandler} variant="outline-success">Logout</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header;

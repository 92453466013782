import React from 'react';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import authenticationService from '../services/authenticationService'
import { useQueryClient } from 'react-query';

function MissionComponent(props) {
  const queryClient = useQueryClient();

  const remove = () => {
    confirmAlert({
      title: 'Confirm deletion',
      message: 'Are you sure you want to delete ' + props.missionName,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios.delete("https://api.markkoudstaal.nl/missions", { headers: { "x-access-token": authenticationService.getToken() }, data: { name: props.missionName } })
              .then(
                (response) => {
                  setTimeout(() => { queryClient.invalidateQueries('missions') }, 500);
                },
                (error) => {
                  this.setState({
                    error
                  })
                }
              );
          }
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  }

  return (
    <div className="missionName">{props.missionName}<Button onClick={remove} variant="danger" className="float-right">Delete</Button></div>
  )
}

export default MissionComponent;
